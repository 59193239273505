import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { navigateTo } from 'gatsby-link';

export default ({ data }) => {
  const { markdownRemark: page, site } = data;

  return (
    <div className="bookingSuccessPage">
      <Helmet
        title={`${site.siteMetadata.title} | ${page.frontmatter.title}`}
      />

      <div className="bookingSuccessPage__header">
        <div className="bookingSuccessPage__iconBox">
          <i className="bookingSuccessPage__icon oi oi-check" />
        </div>
      </div>
      <div className="bookingSuccessPage__body">
        <h4>Hotovo!</h4>
        <p>Vaše poptávka byla odeslána.</p>
        <button
          className="btn btn-primary"
          onClick={() => navigateTo('/rezervace')}
        >
          <span>Pokračovat</span>
        </button>
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query BookingSuccessPageQuery($path: String!) {
    markdownRemark(frontmatter: { urlPath: { eq: $path } }) {
      frontmatter {
        title
      }
    }

    site {
      siteMetadata {
        title
      }
    }
  }
`;
